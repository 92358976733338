import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { LocalContextProvider } from '@/contexts/LocalContext.tsx';
import { ModalContextProvider } from '@/contexts/ModalContext.tsx';

import App from './App';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 10,
      refetchOnMount: false,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: 1000 * 60 * 3,
    },
  },
});

root.render(
  <React.StrictMode>
    {/*<FpjsProvider*/}
    {/*  loadOptions={{*/}
    {/*    apiKey: 'gXQs4XxpNgzb6HlMtd74',*/}
    {/*    endpoint: ['https://eu.api.fpjs.io', 'https://api.fpjs.io', 'https://ap.api.fpjs.io'],*/}
    {/*    region: 'ap',*/}
    {/*  }}*/}
    {/*>*/}
    <QueryClientProvider client={queryClient}>
      {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      <LocalContextProvider>
        <ModalContextProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </ModalContextProvider>
      </LocalContextProvider>
    </QueryClientProvider>

    <ToastContainer
      closeButton={false}
      closeOnClick
      draggable
      draggableDirection="y"
      newestOnTop
      position="top-center"
    />
    {/*</FpjsProvider>*/}
  </React.StrictMode>,
);
